import './App.css';
import Slider from "react-slick";
import React from 'react';

function App() {
  document.body.style = 'background: #27272a';
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
    <div className="bg-zinc-900 text-zinc-100 shadow-2xl">
      <div className=" p-[50px] flex flex-row justify-items-center content-center">
        <div className="basis-1/2">
          <p className='text-[55px] text-zinc-400 font-semi bold'>Hello, my name is <span className='text-zinc-100 font-bold'>tmasik.t</span></p>
          <p className=' text-zinc-300 pl-1'>I am a {new Date().getFullYear()-2010} y. o. developer, a DevOps enthusiast, and a Linux lover from Russia.</p>
          <p className=' text-zinc-400 text-sm mb-2 pl-1'>
            last seen just now
          </p>
          <p className=' text-zinc-300 mb-2 pt-2'>
            <a href="https://t.me/tmasikt" target="_blank" className="bg-blue-500/15 text-blue-400 font-semibold m-1 pl-3 pr-3 p-1 rounded-full">telegram</a>
            <a href="https://reddit.com/u/tmasikt" target="_blank" className="bg-orange-500/15 text-orange-400 font-semibold m-1 pl-3 pr-3 p-1 rounded-full">reddit</a>
            <a href="https://github.com/tmasikt" target="_blank" className="bg-stone-500/15 flex-row text-zinc-400 font-semibold m-1 pl-3 pr-3 p-1 rounded-full">
              github
            </a>
          </p>
        </div>
        <div className='basis-1/2 text-center'>
        </div>
      </div>
    </div>
    <div className='bg-zinc-800 p-5 text-zinc-100  pt-10 content-center items-center'>
      <div className='flex-row flex place-content-center'>
        <div className='basis-1/2 pl-5'>
          <h1 className='text-5xl font-bold text-zinc-100'>About Me</h1>
          <p className='text-zinc-400 pt-4 text-lg indent-1'>I am a young full-stack web developer, Linux lover, and DevOps enthusiast. I started programming when I was 9 years old, and I loved it. Now, I know a lot about Cyber Security, Linux, Web Programming, and AI. I am always happy to help if you have any problems or need something coded for you. I will always be happy to assist you!</p>
        </div>
        <div className='basis-1/2 pl-5'>  
          <h1 className='text-5xl font-bold text-zinc-100'>Contact Me</h1>
          <p className='text-zinc-400 pt-4 text-lg indent-1'>You can always contact me on Reddit, Instagram, or X, but I will answer faster on Telegram. If you have any questions, feel free to ask me!</p>
        </div>
      </div>
      <div className='grid items-center justify-items-center'>
      <h1 className='text-5xl font-bold text-zinc-100 text-center pt-7 pb-7'>My Rice</h1>
      <img src="https://github.com/maxtaran2010/hyprland-rice/raw/main/2023-07-07-193611_hyprshot.png" width="75%" className='self-center rounded-md'/>
      </div>
    </div>
    </div>
  );
}

export default App;
